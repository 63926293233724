<template>
  <div>
    <b-overlay :show="processing" bg-color="#ffffff" z-index="501" opacity="0.6">
      <h6 class="mb-1 mt-1 text-center">註冊</h6>
      <validation-error-alert :errors="validationErrors" v-if="validationErrors"></validation-error-alert>
      <b-form @submit.prevent="" class="av-tooltip tooltip-label-bottom">
        <div class="form-group">
          <label class="float-label">Email</label>
          <input type="email" class="form-control" v-model="email" placeholder="電子郵件"/>
        </div>

        <div class="form-group">
          <label class="float-label">密碼</label>
          <input type="password" class="form-control" v-model="password"/>
        </div>

        <div class="form-group">
          <label class="float-label">確認密碼</label>
          <input type="password" class="form-control" v-model="password_confirmation"/>
        </div>

        <div class="form-group">
          <label class="float-label">姓名</label>
          <input type="text" class="form-control" v-model="last_name" placeholder="蔡依林"/>
        </div>

        <div class="form-group">
          <label class="float-label">稱謂</label>
          <div class="row" style="padding-top: 20px;">
            <div class="col-6">
              <button type="button" class="btn btn-block" v-bind:class="{'btn-outline-secondary': sexual !== 'female', 'btn-outline-primary': sexual === 'female'}" @click="selectSexual('female')">小姐</button>
            </div>
            <div class="col-6">
              <button type="button" class="btn btn-block" v-bind:class="{'btn-outline-secondary': sexual !== 'male', 'btn-outline-primary': sexual === 'male'}" @click="selectSexual('male')">先生</button>
            </div>
          </div>
        </div>

        <div class="form-group">

          <!-- <input type="date" style="padding-top:14px;" class="form-control" v-model="birthday" placeholder="請輸入生日"/> -->
          <div class="row">
            <div class="col-4">
              <label class="float-label">年</label>
              <select class="form-control" placeholder="年" v-model="birthday.year">
                <option :value="null">請選擇</option>
                <option v-for="(year,index) in yearSelects" v-bind:key="index">{{year}}</option>
              </select>
            </div>

            <div class="col-4">
              <label class="float-label">月</label>
              <select class="form-control" v-model="birthday.month">
                <option :value="null">請選擇</option>
                <option v-for="(month,index) in monthSelects" v-bind:key="index">{{month}}</option>
              </select>
            </div>

            <div class="col-4">
              <label class="float-label">日</label>
              <select class="form-control" v-model="birthday.day">
                <option :value="null">請選擇</option>
                <option v-for="(day,index) in daySelects" v-bind:key="index">{{day}}</option>
              </select>
            </div>
          </div>

        </div>

        <b-button class="btn-block secondary-button mt-4" size="lg" :disabled="processing" @click="submit">
          註冊
        </b-button>
        <div class="mt-4">
          <router-link :to="{path: '/' + $route.params.org_code + '/liff/login'}">返回登入</router-link>
        </div>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import axios from 'axios'
import {zh} from 'vuejs-datepicker/dist/locale';


export default {
  data() {
    return {
      processing: false,
      email: null,
      password: null,
      password_confirmation: null,
      validationErrors: null,
      last_name: null,
      birthday: {
        year: null,
        month: null,
        day: null,
      },
      sexual: 'female',
      zh: zh,
    }
  },
  computed:{
    ...mapState('liffCustomerBind', {
      organization: state => state.organization,
      themeConfig: state => state.themeConfig,
      lineData: state => state.lineData,
    }),
    yearSelects() {
      var date = new Date();
      let years = [];
      for (var i = date.getFullYear(); i > 1911; i-- ) {
        years.push(i);
      }
      return years;
    },
    monthSelects() {
      let months = [];
      for (var i = 1; i <= 12; i ++) {
        months.push(i);
      }
      return months;
    },
    daySelects() {
      let days = [];
      for (var i = 1; i <= 31; i ++) {
        days.push(i);
      }
      return days;
    },
  },
  methods: {
    selectSexual(sexual) {
      this.sexual = sexual;
    },
    async submit() {
      let _this = this;
      _this.processing = true;

      const url = process.env.VUE_APP_API_BASE_URL + `/${this.organization.code}/liff/register`
      let params = new URL(location.href).searchParams

      let birthday = null;
      if (_this.birthday.year !== null && _this.birthday.month !== null && _this.birthday.day !== null) {
        birthday = _this.birthday.year + "-" + _this.birthday.month + "-" + _this.birthday.day;
      }

      await axios.post(url, {
        line_id: _this.lineData.profile.userId,
        line_access_token: _this.lineData.accessToken,
        email: _this.email,
        password: _this.password,
        password_confirmation: _this.password_confirmation,
        last_name: _this.last_name,
        birthday: birthday,
        sexual: _this.sexual,
        redirect_url: params.get('redirect_url') ?? null,
      }).then(response => {
        if (response.data.status === 'success' && response.data.data.url) {
          this.$swal.fire({
            title: '註冊成功',
            type: 'success',
            html: '<p>折價券已經在您的帳戶中囉！</p><p style="color:red;">*結帳時點擊折價券即可使用</p>',
            confirmButtonText: '確定'
          }).then(() => {
            window.location.href = response.data.data.url;
          })
        } else {
          let message = (response.data.data.errorCode === 38) ? '帳號已經存在' : null;

          this.$swal.fire({
            title: '註冊失敗',
            text: message,
            type: 'error'
          })
          _this.processing = false;
        }
      }).catch(error => {
        console.error(error);
        if (error.response.status === 422) {
          this.validationErrors = error.response.data.message;
          window.scrollTo(0,0);
        } else {
          this.$swal.fire({
            title: '註冊失敗',
            type: 'error'
          })
        }
        _this.processing = false;
      });
    }
  }
}
</script>

<style>
.form-group {
  margin-bottom: 4px;
}

.btn-outline-secondary {
  border-color: #cdd6dc
}
</style>
